import React, {useEffect, useState} from "react";

import {Grid, MenuItem, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography} from "@mui/material";

import {InsightResult} from "@/types";
import { LifestyleCard } from "./LifestyleCard";
import { LifestyleTableCard } from "./LifestyleTableCard";
import useStyles from "./LifestyleTab.styles";
import {BarChart, TableChart} from "@mui/icons-material";

interface LifestyleTabProps {
  data: InsightResult[];
}

const LifestyleTab: React.FC<LifestyleTabProps> = ({ data }) => {

  const styles = useStyles();

  const [group, setGroup] = useState<string>('Financial Services');
  const [groupData, setGroupData] = useState<InsightResult[]>([]);
  const [view, setView] = useState<string>("graph");

  useEffect(() => {
    setGroupData(data.filter((x) => x.tag_group === group));
  }, [group, data])

  const tagGroups = Array.from(new Set(data.map(val => val.tag_group)));

  const handleGroupSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGroup(e.target.value);
  };

  const handleViewChange = (_e: React.MouseEvent<HTMLElement>, newVal: string | null) => {
    if (newVal !== null) setView(newVal);
  };

  console.log(groupData);


  return (
    <>
      <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
        <TextField
          id="group-select"
          label="Choose a Group"
          variant="outlined"
          select
          value={group}
          onChange={handleGroupSelect}
          InputProps={{className: styles.textBox, disableUnderline: true}}
        >
          {tagGroups.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <div>
          <Typography variant="overline">View Type</Typography>
          <div>
            <ToggleButtonGroup
              value={view}
              onChange={handleViewChange}
              exclusive
              size="small"
              aria-label="view change"
            >
              <ToggleButton value="table" aria-label="table">
                <Tooltip title="Table view" placement="left">
                  <TableChart/>
                </Tooltip>
              </ToggleButton>
              <ToggleButton value="graph" aria-label="graph">
                <Tooltip title="Graph view" placement="right">
                  <BarChart/>
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
      </Grid>
      <Grid item xs={12}>
      {view === 'graph' ?
        <LifestyleCard name={group} data={groupData}/>
        :
        <LifestyleTableCard name={group} data={groupData}/>
      }
      </Grid>
    </>

  )

};

export default LifestyleTab;
