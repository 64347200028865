import { createStyles, makeStyles } from "@mui/styles";

const useCustomerDiscoverStyles = makeStyles(() =>
  createStyles({
    topPanel: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    viewType: {
      position: "relative",
      top: "-14px",
    },
    gridContainer: {
      maxHeight: "250px",
      overflowX: "auto",
      padding: "8px",
    },
    tableText: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      fontSize: "14px",
    },
    rowDiv: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    barText: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      fontSize: "14px",
      marginLeft: "4px",
    },
    marginText: {
      marginRight: "4px",
    },
    title: {
      marginTop: "1em",
    },
    downloadButtonGrid: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "1em",
    },
    downloadButton: {
      color: "#bdbdbd",
    },
    card: {
      background: "#FAFAFA",
    },
    cardHeader: {
      fontFamily: "cera-pro-bold",
      textTransform: "uppercase",
    },
    textBox: {
      borderRadius: "4px",
      minWidth: "400px"
    },
  })
);

export default useCustomerDiscoverStyles;
