import { discoverAudiences } from "@/api/audience";
import { InsightResult, PenPortrait } from "@/types";
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";

const useAudienceDiscover = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const getAudienceDiscoverData = async (requestId: string, geo: string) => {
    setLoading(true);
    setError(null);
    try {
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await discoverAudiences(
        accessToken,
        requestId,
        geo
      );
      if (error) {
        setError(error.message);
      } else {
        const result: {
          insights: Array<InsightResult>;
          locations: Array<InsightResult>;
          pen_portrait: PenPortrait;
        } = {
          insights: data.insight,
          locations: data.locations,
          pen_portrait: {
            location: data.pen_portrait.location,
            insight: data.pen_portrait.insight,
            questions: data.pen_portrait.questions,
            demographics: data.pen_portrait.demographics,
            demographics_all: data.pen_portrait.demographics_all,
            description: data.pen_portrait.description,
            image: data.pen_portrait.image,
            size: data.pen_portrait.size,
          },
        };
        return result;
      }
    } catch (err) {
      setError((err as Error).message);
    } finally {
      setLoading(false);
    }
  };

  return { getAudienceDiscoverData, loading, error };
};

export default useAudienceDiscover;
