import React, { useState } from "react";
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@mui/material";

import useConfigureBuildStyles from "./ConfigureBuild.styles";

interface ConfigureBuildProps {
  globalGeo: string;
  globalMode: string;
  onGeoUpdate: (geo: string) => void;
  onModeUpdate: (mode: string) => void;
}

const ConfigureBuild: React.FC<ConfigureBuildProps> = ({
  globalGeo,
  globalMode,
  onGeoUpdate,
  onModeUpdate,
}) => {
  const styles = useConfigureBuildStyles();

  const [geo, setGeo] = useState<string>(globalGeo);
  const [mode, setMode] = useState<string>(globalMode);

  const handleGeoChange = (value: string) => {
    setGeo(value);
    onGeoUpdate(value);
  };

  const handleModeChange = (value: string) => {
    setMode(value);
    onModeUpdate(value);
  };

  return (
    <Grid item container xs={12}>
      <Typography gutterBottom variant="h6" className={styles.title}>
        Configure
      </Typography>
      <Grid item xs={12}>
        <Typography gutterBottom variant="h6" className={styles.subtitle}>
          Geography
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="geo"
            name="geo"
            value={geo}
            onChange={(event) => handleGeoChange(event.target.value)}
            row
          >
            <Tooltip title="Full Postcodes">
              <FormControlLabel
                value="postcode"
                control={<Radio color="primary" />}
                label="Postcode"
                labelPlacement="top"
              />
            </Tooltip>
            {/*<Tooltip title="Postcode Sectors">*/}
            {/*  <FormControlLabel*/}
            {/*    value="sector"*/}
            {/*    control={<Radio color="primary" />}*/}
            {/*    label="Sector"*/}
            {/*    labelPlacement="top"*/}
            {/*  />*/}
            {/*</Tooltip>*/}
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Typography gutterBottom variant="h6" className={styles.subtitle}>
          Audience Granularity
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="mode"
            name="mode"
            value={mode}
            onChange={(event) => handleModeChange(event.target.value)}
            row
          >
            <Tooltip title="All over-indexing geographies">
              <FormControlLabel
                value="broad"
                control={<Radio color="primary" />}
                label="High Reach"
                labelPlacement="top"
              />
            </Tooltip>
            <Tooltip title="A balance of high scoring and high volume geographies">
              <FormControlLabel
                value="balanced"
                control={<Radio color="primary" />}
                label="Balanced"
                labelPlacement="top"
              />
            </Tooltip>
            <Tooltip title="The top scoring geographies for optimized targeting">
              <FormControlLabel
                value="narrow"
                control={<Radio color="primary" />}
                label="High Scoring"
                labelPlacement="top"
              />
            </Tooltip>
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default ConfigureBuild;
