import React, {useMemo} from "react";

import {InsightTable, InsightTableHeader} from "@/types";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';

import TableRow from '@mui/material/TableRow';


import SortableHeader from "./SortableHeader"
import {toPercentage, toIndex} from "@/utils/common";
import {Order} from "@/types";
import { orderBy } from "lodash";
import {useTheme} from "@mui/material/styles";


interface DenseTableProps {
  header: InsightTableHeader[];
  data: InsightTable[];
}

const DenseTable: React.FC<DenseTableProps> = ({ header, data }) => {

  const theme = useTheme();

  const [order, setOrder] = React.useState<Order>('desc');
  const [column, setColumn] = React.useState<keyof InsightTable>('index');

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof InsightTable,
  ) => {
    const isAsc = column === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setColumn(property);
  };

  const rows = useMemo(() => {
     return orderBy(data, column, order);
  }, [data, order, column])

  return (
    <TableContainer sx={{ maxWidth: '100%'}}>
      <Table size="small" sx={{tableLayout: 'fixed'}}>
        <SortableHeader header={header} column={column} order={order} onSort={handleRequestSort} />
        <TableBody>
          {rows.map((row) => (
            <TableRow>
              <TableCell
                sx={{ width: '36%', whiteSpace: "noWrap", overflow: "hidden", textOverflow: "ellipsis"}}
              >
                {row.name}
              </TableCell>
              <TableCell
                align="right"
                sx={{ width: '24%'}}
              >
                {toPercentage(row.rate)}
              </TableCell>
              <TableCell
                align="right"
                sx={{ width: '22%'}}
              >
                {toPercentage(row.base)}
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  width: '18%',
                  color: row.index > 1 ? theme.palette.success.main : theme.palette.error.main
                }}
              >
                {toIndex(row.index)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DenseTable;
