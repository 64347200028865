import {
  useQueryGroupSelectionStyles
} from './QueryGroupSelection.styles';
import {
  Grid,
  IconButton,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Tag, Variable } from '@/types';

import { Replay } from '@mui/icons-material';
import { VariableSelection } from '@/components/atoms';
import { operators } from '@/constants';

interface QueryGroupSelectionProps {
  categories: Array<string>;
  groupId: number;
  tags: Array<Tag>;
  groupSelection: Array<Variable>;
  groupOperator: string;
  onSelectionUpdate: (groupId: number, selection: Array<Variable>) => void;
  onOperatorUpdate: (id: number, operator: string) => void;
}

const QueryGroupSelection: React.FC<QueryGroupSelectionProps> = ({
  categories,
  groupId,
  tags,
  groupSelection,
  groupOperator,
  onSelectionUpdate,
  onOperatorUpdate,
}) => {
  const styles = useQueryGroupSelectionStyles();
  const [selection, setSelection] = useState<Array<Variable>>(groupSelection);
  const [operator, setOperator] = useState<string>(groupOperator);

  useEffect(() => {
    setSelection(groupSelection);
    setOperator(groupOperator);
  }, [groupSelection, groupOperator]);

  const handleOperatorSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setOperator(value);
    onOperatorUpdate(groupId, value);
  };

  const handleSelectionChange = (_event: any, values: Variable[]) => {
    setSelection(values);
    onSelectionUpdate(groupId, values);
  };

  const handleReset = () => {
    setSelection([]);
    onSelectionUpdate(groupId, []);
  };

  return (
    <Grid
      container
      spacing={2}
      item
      xs={11}
      component={Paper}
      className={styles.groupPaper}
    >
      <Grid item xs={12} className={styles.queryGroupHeader}>
        <Typography variant="button" color="primary">
          Query group {groupId}
        </Typography>
        <div className={styles.rowDiv}>
          <IconButton
            color="primary"
            size="small"
            onClick={handleReset}
            className={styles.resetButton}
          >
            <Replay />
          </IconButton>
          <TextField
            variant="outlined"
            size="small"
            color="primary"
            select
            value={operator}
            onChange={handleOperatorSelect}
          >
            {operators.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </Grid>
      {tags.length > 0 &&
      <VariableSelection
        categories={categories}
        groupId={groupId}
        groupSelection={selection}
        tags={tags}
        onSelectionUpdate={handleSelectionChange}
      />
      }
    </Grid>
  );
};

export default QueryGroupSelection;
