import {
  AudienceBuild,
  AudienceDiscover,
  AudienceExport,
} from "@/components/organisms";
import {
  AudienceDiscoverInsight,
  AudienceSize,
  Audience as AudienceType,
  Tag,
  Variable,
  VariableGroup,
} from "@/types";
import { Button, Grid, Step, StepLabel, Stepper, Tooltip } from "@mui/material";
import { Path, PathStepIcon } from "@/components/atoms";
import { PathStepConnector, useGlobalStyles } from "@/styles";
import React, { useEffect, useState } from "react";
import { deleteAudience, favouriteAudience } from "@/api/audience";

import { MainLayout, PageLayout } from "@/components/layouts";
import { SavedAudiences } from "@/components/molecules";
import { appFlows } from "@/constants";
import { getAudiences } from "@/api";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";

const steps = ["CRAFT", "INVESTIGATE", "ACTIVATE"];

const Audience = () => {
  const { name, description } = appFlows[1];
  const styles = useGlobalStyles();
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();

  const [queryGroups, setQueryGroups] = useState<Array<VariableGroup>>([
    { id: 1, variables: [], operator: "AND" },
  ]);
  const [mainOperator, setMainOperator] = useState<string>("AND");
  const [geo, setGeo] = useState<string>("postcode");
  const [mode, setMode] = useState<string>("broad");
  const [audienceSize, setAudienceSize] = useState<AudienceSize | null>(null);
  const [phase, setPhase] = useState<number>(0);
  const [insight, setInsight] = useState<AudienceDiscoverInsight | null>(null);
  const [tags, setTags] = useState<Array<Tag>>([]);
  const [selection, setSelection] = useState<Array<Tag>>([]);
  const [finish, setFinish] = useState<boolean>(false);
  const [audiences, setAudiences] = useState<Array<AudienceType>>([]);
  const [audienceName, setAudienceName] = useState<string>("");
  const [displaySavedAudiences, setDisplaySavedAudiences] =
    useState<boolean>(true);

  useEffect(() => {
    const fetchAudiences = async () => {
      const token = await getAccessTokenSilently();
      const { data } = await getAudiences(token);
      if (data) setAudiences(data);
    };

    fetchAudiences();
  }, [getAccessTokenSilently]);

  const resetState = () => {
    setQueryGroups([{ id: 1, variables: [], operator: "AND" }]);
    setMainOperator("AND");
    setAudienceSize(null);
    setInsight(null);
    setSelection([]);
    setFinish(false);
    setAudienceName("");
  };

  const handleNext = () => setPhase((prevPhase) => prevPhase + 1);

  const handleBack = () => setPhase((prevPhase) => prevPhase - 1);

  const handleFinish = () => {
    setPhase(0);
    resetState();
  };

  const handleGoBack = () => {
    window.location.href = '/'
  };

  const handleGroupAdd = () => {
    const prevId = Math.max.apply(
      Math,
      queryGroups.map((v) => v.id)
    );
    setQueryGroups([
      ...queryGroups,
      { id: prevId + 1, variables: [], operator: "AND" },
    ]);
    setAudienceSize(null);
    setInsight(null);
  };

  const handleGroupDelete = (value: number) => {
    setQueryGroups(queryGroups.filter((v) => v.id !== value));
    setAudienceSize(null);
    setInsight(null);
  };

  const handleSelectionUpdate = (id: number, variables: Variable[]) => {
    let updatedGroups = [...queryGroups];
    updatedGroups = updatedGroups.map((v) => {
      if (v.id === id) {
        return { ...v, variables };
      }
      return v;
    });
    setQueryGroups(updatedGroups);
    setAudienceSize(null);
    setInsight(null);

    const allTags: Tag[] = ([] as Tag[]).concat(
      ...updatedGroups.map((v) =>
        ([] as Tag[]).concat(
          ...v.variables.filter((v) => v.tag_type !== "Location")
        )
      )
    );

    setSelection(allTags);
  };

  const handleOperatorUpdate = (id: number, operator: string) => {
    const updatedGroups = queryGroups.map((v) => {
      if (v.id === id) {
        return { ...v, operator };
      }
      return v;
    });
    setQueryGroups(updatedGroups);
    setAudienceSize(null);
    setInsight(null);
  };

  const handleMainOperatorUpdate = (value: string) => {
    setMainOperator(value);
    setAudienceSize(null);
    setInsight(null);
  };

  const handleGeoUpdate = (geo: string) => {
    setGeo(geo);
    setAudienceSize(null);
    setInsight(null);
  };

  const handleModeUpdate = (mode: string) => {
    setMode(mode);
    setAudienceSize(null);
    setInsight(null);
  };

  const handleInsightUpdate = (insightResult: AudienceDiscoverInsight) =>
    setInsight(insightResult);

  const handleExportUpdate = (selection: Array<Tag>) => setSelection(selection);

  const handleFileDownload = () => setFinish(true);

  const handleDisplaySavedAudiences = () => {
    setDisplaySavedAudiences(true);
    resetState();
  };

  const handleAudienceNameUpdate = (audienceName: string) =>
    setAudienceName(audienceName);

  const handleAudiencesUpdate = (audiences: Array<AudienceType>) => {
    setInsight(null);
    setAudiences(audiences);
    resetState();
  };

  const handleAudienceSizeUpdate = (value: AudienceSize) => {
    setAudienceSize(value);
    setInsight(null);
  };

  const handleAudienceDelete = async (name: string) => {
    const token = await getAccessTokenSilently();

    const { data, error } = await deleteAudience(token, name);

    if (data) setAudiences(data);

    if (error) console.error(error);
  };

  const handleAudienceFavourite = async (name: string, value: any) => {
    const token = await getAccessTokenSilently();

    const { data, error } = await favouriteAudience(token, name, value);

    if (data) setAudiences(data);

    if (error) console.error(error);
  };

  const handleAudienceEdit = (audience: AudienceType) => {
    const item = audiences.find(
      (x) => x.audience_name === audience.audience_name
    );

    if (item) {
      setQueryGroups(item.variable_groups);
      setGeo(item.geo);
      setMode(item.mode);
      setAudienceName(item.audience_name);
      setAudienceSize(null);
      setInsight(null);
      const allTags = item.variable_groups.flatMap((v) =>
        v.variables.filter((v) => v.tag_type !== "Location")
      );
      setSelection(allTags);
      setDisplaySavedAudiences(false);
    }
  };

  const handleAudienceUpdate = (audienceSize: AudienceSize | null) => {
    if (audienceSize) {
      setAudienceSize(audienceSize);
      setInsight(null);
    }
  };

  const handleTagsUpdate = (tags: Array<Tag>) => setTags(tags);

  const renderPhase = () => {
    switch (phase) {
      case 0:
        return (
          <Path
            phase={phase}
            steps={steps}
            backDisabled={true}
            nextDisabled={!audienceSize || audienceSize.num_postcodes === "0"}
            handleBack={handleBack}
            handleNext={handleNext}
          >
            <Tooltip title="View saved audiences" aria-label="saved-audiences">
              <Button
                onClick={handleDisplaySavedAudiences}
                size="large"
                variant="outlined"
                color="primary"
                style={{ marginBottom: "12px" }}
              >
                SAVED AUDIENCES
              </Button>
            </Tooltip>
            <AudienceBuild
              audiences={audiences}
              globalAudienceName={audienceName}
              onGroupAdd={handleGroupAdd}
              onGroupDelete={handleGroupDelete}
              onSelectionUpdate={handleSelectionUpdate}
              onOperatorUpdate={handleOperatorUpdate}
              onMainOperatorUpdate={handleMainOperatorUpdate}
              onGeoUpdate={handleGeoUpdate}
              onModeUpdate={handleModeUpdate}
              onAudienceUpdate={handleAudienceUpdate}
              onAudienceSizeUpdate={handleAudienceSizeUpdate}
              onAudiencesUpdate={handleAudiencesUpdate}
              onAudienceNameUpdate={handleAudienceNameUpdate}
              onTagsUpdate={handleTagsUpdate}
              globalAudienceSize={audienceSize}
              globalQueryGroups={queryGroups}
              globalMainOperator={mainOperator}
              globalGeo={geo}
              globalMode={mode}
              globalTags={tags}
            />
          </Path>
        );
      case 1:
        return (
          <Path
            phase={phase}
            steps={steps}
            handleBack={handleBack}
            handleNext={handleNext}
          >
            <AudienceDiscover
              tags={tags}
              queryGroups={queryGroups}
              audienceSize={audienceSize}
              geo={geo}
              insightResult={insight}
              onInsightUpdate={handleInsightUpdate}
            />
          </Path>
        );
      case 2:
        return (
          <Path
            phase={phase}
            steps={steps}
            backDisabled={finish}
            nextDisabled={selection.length > 10}
            handleBack={handleBack}
            handleNext={handleFinish}
          >
            <AudienceExport
              audienceSize={audienceSize}
              globalTags={tags}
              globalSelection={selection}
              onFileDownload={handleFileDownload}
              onSelectionUpdate={handleExportUpdate}
              onTagsUpdate={handleTagsUpdate}
            />
          </Path>
        );
      default:
        return null;
    }
  };

  return (
    <MainLayout>
      <PageLayout name={name} description={description} onBack={handleGoBack}>
        {displaySavedAudiences ? (
          <Grid container spacing={2}>
            <Grid item xs={12} className={styles.rightButton}>
              <Button
                onClick={() => setDisplaySavedAudiences(false)}
                size="large"
                variant="contained"
                color="primary"
                fullWidth={false}
                style={{ marginRight: "24px" }}
              >
                BUILD NEW AUDIENCE
              </Button>
            </Grid>
            <Grid item xs={12}>
              <SavedAudiences
                audiences={audiences}
                onAudienceDelete={handleAudienceDelete}
                onAudienceFavourite={handleAudienceFavourite}
                onAudienceEdit={handleAudienceEdit}
              />
            </Grid>
          </Grid>
        ) : (
          <div className={styles.root}>
            <Grid
              container
              spacing={3}
              alignItems="center"
              alignContent="center"
              className={styles.stepper}
            >
              <Grid item xs={12}>
                <Stepper
                  activeStep={phase}
                  alternativeLabel
                  className={styles.stepPaper}
                  connector={<PathStepConnector />}
                >
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepLabel
                        classes={{
                          label: styles.stepLabel,
                          active: styles.stepLabelBold,
                          completed: styles.stepLabelBold,
                        }}
                        StepIconComponent={() => (
                          <PathStepIcon
                            icon={index + 1}
                            active={phase === index}
                            completed={phase > index}
                          />
                        )}
                      >
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
            </Grid>
            {renderPhase()}
          </div>
        )}
      </PageLayout>
    </MainLayout>
  );
};

export default Audience;
