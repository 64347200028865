import { ApiError } from "@/interfaces/api";
import { AxiosRequestConfig } from "axios";
import { fetchData } from "@/api/utils";
import { CustomerDiscoverInsight } from "@/types";

type DiscoverCustomerApiResponse = {
  data: CustomerDiscoverInsight;
  error: ApiError | null;
};

export const customerDiscover = async (
  accessToken: string,
  hasHeader: boolean,
  header: string,
  delimiter: string,
  file: File | null | undefined
): Promise<DiscoverCustomerApiResponse> => {
  let url = `${process.env.REACT_APP_API_URL}/t7s/customer/discover`;
  url = hasHeader
    ? url + `?delimiter=${delimiter}`
    : url + `?delimiter=${delimiter}&header=${header}`;

  const config: AxiosRequestConfig = {
    method: "POST",
    url,
    headers: {
      "content-type": "text/csv",
      Authorization: `Bearer ${accessToken}`,
    },
    data: file,
  };

  return fetchData(config);
};
