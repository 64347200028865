import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Step, StepLabel, Stepper } from "@mui/material";

import { PathStepIcon, Path } from "@/components/atoms";
import { MainLayout, PageLayout } from "@/components/layouts";
import {
  CustomerConnect,
  CustomerDiscover,
  CustomerLookalikes,
  CustomerUpload,
} from "@/components/organisms";

import {CustomerDiscoverInsight, FileInfo, Tag} from "@/types";
import { appFlows } from "@/constants";

import { PathStepConnector, useGlobalStyles } from "@/styles";

const steps = ["Upload", "Investigate", "Activate", "Lookalikes"];

const Customer = () => {
  const { name, description } = appFlows[0];
  const styles = useGlobalStyles();
  const history = useHistory();

  const [phase, setPhase] = useState(0);
  const [fileInfo, setFileInfo] = useState<FileInfo | null>(null);
  const [insight, setInsight] = useState<CustomerDiscoverInsight | null>(null);
  const [tags, setTags] = useState<Array<Tag>>([]);
  const [selection, setSelection] = useState<Array<Tag>>([]);
  const [connectFinish, setConnectFinish] = useState(false);
  const [segments, setSegments] = useState<string[]>([]);

  const resetState = () => {
    setFileInfo({
      file: null,
      delimiter: ",",
      hasHeader: true,
      header: "",
      confirm: false,
      preview: [],
      valid: false,
    });
    setInsight(null);
    setSelection([]);
    setConnectFinish(false);
    setSegments([]);
  };

  const handleNext = () => setPhase((prevPhase) => prevPhase + 1);

  const handleBack = () => setPhase((prevPhase) => prevPhase - 1);

  const handleFinish = () => {
    setPhase(0);
    resetState();
  };

  const handleGoBack = () => {
    window.location.href = '/'
  }

  const handleFileUpdate = (
    file: File | null,
    delimiter: string | null,
    hasHeader: boolean,
    header: string | null,
    confirm: boolean,
    preview: string[] | null,
    valid: boolean
  ) => {
    const data = {
      file,
      delimiter,
      hasHeader,
      header,
      confirm,
      preview,
      valid,
    };
    setFileInfo(data);
    setInsight(null);
    setSelection([]);
    setSegments([]);
  };

  const handleSegmentsUpdate = (segments: string[]) => setSegments(segments);

  const handleInsightUpdate = (insight: CustomerDiscoverInsight) =>
    setInsight(insight);

  const handleTagsUpdate = (tags: Array<Tag>) => setTags(tags);

  const handleSelectionUpdate = (tags: Tag[]) => setSelection(tags);

  const handleFileDownload = () => setConnectFinish(true);

  const renderPhase = () => {
    switch (phase) {
      case 0:
        return (
          <Path
            phase={phase}
            steps={steps}
            backDisabled={true}
            nextDisabled={!fileInfo?.valid}
            handleBack={handleBack}
            handleNext={handleNext}
          >
            <CustomerUpload
              fileInfo={fileInfo}
              onFileUpdate={handleFileUpdate}
              onSegmentsUpdate={handleSegmentsUpdate}
            />
          </Path>
        );
      case 1:
        return (
          <Path
            phase={phase}
            steps={steps}
            handleBack={handleBack}
            handleNext={handleNext}
          >
            <CustomerDiscover
              globalTags={tags}
              fileInfo={fileInfo}
              insightResult={insight}
              onTagsUpdate={handleTagsUpdate}
              onInsightUpdate={handleInsightUpdate}
            />
          </Path>
        );
      case 2:
        return (
          <Path
            phase={phase}
            steps={steps}
            backDisabled={connectFinish}
            handleBack={handleBack}
            handleNext={handleNext}
          >
            <CustomerConnect
              fileInfo={fileInfo}
              globalTags={tags}
              globalSelection={selection}
              exportComplete={connectFinish}
              onTagsUpdate={handleTagsUpdate}
              onSelectionUpdate={handleSelectionUpdate}
              onFileDownload={handleFileDownload}
            />
          </Path>
        );
      case 3:
        return (
          <Path
            phase={phase}
            steps={steps}
            backDisabled={false}
            handleBack={handleBack}
            handleNext={handleFinish}
          >
            <CustomerLookalikes
              fileInfo={fileInfo}
              segments={segments}
              onFileDownload={handleFileDownload}
            />
          </Path>
        );
      default:
        return null;
    }
  };

  return (
    <MainLayout>
      <PageLayout description={description} name={name} onBack={handleGoBack}>
        <div className={styles.root}>
          <Grid
            container
            spacing={3}
            alignItems="center"
            alignContent="center"
            className={styles.stepper}
          >
            <Grid item xs={12}>
              <Stepper
                activeStep={phase}
                alternativeLabel
                className={styles.stepPaper}
                connector={<PathStepConnector />}
              >
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel
                      classes={{
                        label: styles.stepLabel,
                        active: styles.stepLabelBold,
                        completed: styles.stepLabelBold,
                      }}
                      StepIconComponent={() => (
                        <PathStepIcon
                          icon={index + 1}
                          active={phase === index}
                          completed={phase > index}
                        />
                      )}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
          </Grid>
          {renderPhase()}
        </div>
      </PageLayout>
    </MainLayout>
  );
};

export default Customer;
