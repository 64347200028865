import React from "react";

export const Logo2 = () => (
  <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 404.76 393.03">
    <g id="Layer_1-2" data-name="Layer_1">
      <g>
        <g>
          <path fill="#fff"
                d="M8.29,340.8v-15.81h11.52v15.81h12.15v8.69h-12.15v17.64c0,6.05,1.53,8.61,7.46,8.61h4.69v8.72h-10.48c-8.39,0-13.19-4.74-13.19-13.67v-21.31H.15v-8.69h8.14Z"/>
          <path fill="#fff"
                d="M51.22,324.99v15.81h10.24c12.71,0,18.27,4.61,18.27,15.97v27.7h-11.64v-26.37c0-5.77-1.65-7.7-7.68-7.7h-9.2v34.07h-11.44v-59.48h11.44Z"/>
          <path fill="#fff"
                d="M86.14,362.64c0-14.23,7.44-21.85,21.96-21.85h16.81v9.11h-15.23c-7.39,0-10.52,1.22-10.79,8.19h26.02v9.13h-26.02c.27,7.18,3.27,8.11,10.7,8.11h15.32v9.13h-16.81c-14.51,0-21.96-7.48-21.96-21.82Z"/>
          <path fill="#fff" d="M133.52,337.31v-11.49h47.63v11.49l-32.29,55.71h-14.37l33.49-55.71h-34.46Z"/>
          <path fill="#fff"
                d="M209.69,375.33c4.09,0,6.19-1.17,6.19-4.22s-2.09-4.31-6.19-4.31h-9.99c-10.6,0-15.05-2.15-15.05-12.36,0-9.36,4.86-13.65,15.41-13.65h24.76v9.11h-20.38c-5.21,0-7.93.31-7.93,3.97,0,2.73,1.74,3.88,5.44,3.88h10.44c9.61,0,14.66,4.66,14.66,13.04s-5.05,13.67-11.93,13.67h-29.72v-9.13h24.29Z"/>
          <path fill="#fff"
                d="M240.03,340.8v-15.81h11.52v15.81h12.21v8.69h-12.21v17.64c0,6.05,1.59,8.61,7.45,8.61h4.76v8.72h-10.56c-8.34,0-13.18-4.74-13.18-13.67v-21.31h-8.09v-8.69h8.09Z"/>
          <path fill="#fff"
                d="M296.04,340.8c11.92,0,17.99,5.54,17.99,16.31v27.36h-30.96c-8.71,0-13.53-4.57-13.53-12.63,0-10.11,5.23-13.9,17.39-13.9h15.17v-1.09c0-5.53-3.15-7.02-10.43-7.02h-19.21v-9.03h23.57ZM302.17,375.66v-9.05h-13.59c-4.87,0-7.1,1.03-7.1,4.9s2.08,4.15,6.32,4.15h14.38Z"/>
          <path fill="#fff"
                d="M341.52,340.8c9.11,0,12.27,2.88,12.27,11v7.27h-11.04v-5.61c0-3.88-1.29-4.31-5.07-4.31h-4.03v35.31h-11.52v-43.66h19.39Z"/>
          <path fill="#fff"
                d="M384.69,375.33c4.1,0,6.18-1.17,6.18-4.22s-2.08-4.31-6.18-4.31h-9.96c-10.62,0-15.08-2.15-15.08-12.36,0-9.36,4.87-13.65,15.41-13.65h24.76v9.11h-20.37c-5.21,0-7.94.31-7.94,3.97,0,2.73,1.71,3.88,5.42,3.88h10.45c9.65,0,14.66,4.66,14.66,13.04s-5.01,13.67-11.94,13.67h-29.73v-9.13h24.31Z"/>
        </g>
        <polygon fill="#fff" points="231.73 22.83 0 22.83 0 99.55 209.04 99.55 231.73 22.83"/>
        <polygon fill="#fff" points="110.59 135.48 53.83 313.3 138.99 313.3 178.69 186.57 110.59 135.48"/>
        <polygon fill="#fff"
                 points="346.96 292.5 253.11 225.59 159.34 292.66 194.17 181.14 101.18 111.91 217.41 111.49 252.89 0 288.52 111.42 404.76 111.63 310.02 181 346.96 292.5"/>
      </g>
    </g>
  </svg>
);
