import React, {useState} from "react";

import {Grid, ToggleButton, ToggleButtonGroup, Tooltip, Typography} from "@mui/material";
import {BarChart, TableChart} from "@mui/icons-material";

import {InsightResult} from "@/types";
import { BarGraphCard } from "./BarGraphCard";
import { DataTableCard } from "./DataTableCard";
import { StackedBarGraphCard } from "./StackedBarGraphCard";


interface DemographicsTabProps {
  data: InsightResult[];
}

const DemographicsTab: React.FC<DemographicsTabProps> = ({ data }) => {

  const [view, setView] = useState<string>("graph");

  const handleViewChange = (_e: React.MouseEvent<HTMLElement>, newVal: string | null) => {
    if (newVal !== null) setView(newVal);
  };

  const categories = [
    'Age Band', 'Income', 'Lifestage', 'Social Grade', 'Employment Status', 'Relationship Status', 'House Price',
    'Home Ownership', 'Number of Adults', 'Number of Children', 'Commute Method', 'Commute Duration'
  ]

  return (
    <>
      <Grid item xs={12} display="flex" justifyContent="flex-end">
        <div>
          <Typography variant="overline">View Type</Typography>
          <div>
            <ToggleButtonGroup
              value={view}
              onChange={handleViewChange}
              exclusive
              size="small"
              aria-label="view change"
            >
              <ToggleButton value="table" aria-label="table">
                <Tooltip title="Table view" placement="left">
                  <TableChart/>
                </Tooltip>
              </ToggleButton>
              <ToggleButton value="graph" aria-label="graph">
                <Tooltip title="Graph view" placement="right">
                  <BarChart/>
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
      </Grid>
      {categories.map((category, categoryIdx) => (
        <Grid item xs={6} md={4} lg={3} key={categoryIdx}>
          {view === 'graph' ?
            <BarGraphCard name={category} data={data.filter(v => v.tag_category == category)}/>
          :
            <DataTableCard name={category} data={data.filter(v => v.tag_category == category)} />
          }
        </Grid>
      ))}


      {/*<Grid item xs={6} md={4}>*/}
      {/*  <StackedBarGraphCard name={'Location Type'} data={data.filter(v => v.tag_category == 'Urban/Rural')} categories={['Urban/Rural']}/>*/}
      {/*</Grid>*/}
    </>

  )

};

export default DemographicsTab;
