import React, {useMemo} from "react";

import {Card, CardContent, CardHeader } from "@mui/material";

import {InsightResult, LifestyleTable, LifestyleTableHeader, Order} from "@/types";
import LifestyleHeader from "./LifestyleHeader";
import {toIndex, toPercentage} from "@/utils/common";

import {Chart as ChartJS, Legend, BarElement, LinearScale, CategoryScale, Title, Tooltip as ChartTooltip} from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {useTheme} from "@mui/material/styles";
import {orderBy} from "lodash";
import Table from '@mui/material/Table';
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
ChartJS.register(
  BarElement,
  Legend,
  Title,
  ChartTooltip,
  LinearScale,
  CategoryScale,
  ChartDataLabels
);


interface LifestyleTableCardProps {
  name: string;
  data: InsightResult[];
}

export const LifestyleTableCard: React.FC<LifestyleTableCardProps> = ({name, data}) => {

  const theme = useTheme();

  const [order, setOrder] = React.useState<Order>('desc');
  const [column, setColumn] = React.useState<keyof LifestyleTable>('agree_score');

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof LifestyleTable,
  ) => {
    const isAsc = column === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setColumn(property);
  };

  const questions = Array.from(new Set(data.map((v) => v.tag_category)));

  const header : LifestyleTableHeader[] = [
    {
      id: 'question',
      numeric: false,
      disablePadding: false,
      label: 'Lifestyle Statement',
      width: '40%'
    },
    {
      id: 'agree_rate',
      numeric: true,
      disablePadding: false,
      label: 'Agree %',
      width: '10%'
    },
    {
      id: 'neither_rate',
      numeric: true,
      disablePadding: false,
      label: 'Neither %',
      width: '10%'
    },
    {
      id: 'disagree_rate',
      numeric: true,
      disablePadding: false,
      label: 'Disagree %',
      width: '10%'
    },
    {
      id: 'agree_score',
      numeric: true,
      disablePadding: false,
      label: 'Agree Idx',
      width: '10%'
    },
    {
      id: 'neither_score',
      numeric: true,
      disablePadding: false,
      label: 'Neither Idx',
      width: '10%'
    },
    {
      id: 'disagree_score',
      numeric: true,
      disablePadding: false,
      label: 'Disagree Idx',
      width: '10%'
    },
  ];

  const rows = useMemo(() => {
    const dataRows = questions.map((question) => {
      const subData = data.filter((x) => x.tag_category === question);

      return ({
        'question': question,
        'agree_rate': subData.filter(x => x.tag_name === 'Agree')[0].rate,
        'neither_rate': subData.filter(x => x.tag_name === 'Neither Agree nor Disagree')[0].rate,
        'disagree_rate': subData.filter(x => x.tag_name === 'Disagree')[0].rate,
        'agree_score': subData.filter(x => x.tag_name === 'Agree')[0].score,
        'neither_score': subData.filter(x => x.tag_name === 'Neither Agree nor Disagree')[0].score,
        'disagree_score': subData.filter(x => x.tag_name === 'Disagree')[0].score,
      })
    })
    return orderBy(dataRows, column, order);
  }, [order, column, data, questions])

  return (
    <Card elevation={8}>
      <CardHeader title={name}/>
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          pr: 1,
          pl: 1,
          pt: 0,
          pb: 1,
          "&:last-child": { pb: 1 }
        }}
      >
        <TableContainer sx={{ maxWidth: '100%'}}>
          <Table size="small" sx={{tableLayout: 'fixed'}}>
            <LifestyleHeader header={header} column={column} order={order} onSort={handleRequestSort} />
            <TableBody>
              {rows.map((row) => (
                <TableRow>
                  <TableCell
                    sx={{ width: '40%', whiteSpace: "noWrap", overflow: "hidden", textOverflow: "ellipsis"}}
                  >
                    {row.question}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ width: '10%'}}
                  >
                    {toPercentage(row.agree_rate)}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ width: '10%'}}
                  >
                    {toPercentage(row.neither_rate)}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ width: '10%'}}
                  >
                    {toPercentage(row.disagree_rate)}
                  </TableCell>

                  <TableCell
                    align="right"
                    sx={{
                      width: '18%',
                      color: row.agree_score > 1 ? theme.palette.success.main : theme.palette.error.main
                    }}
                  >
                    {toIndex(row.agree_score)}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      width: '18%',
                      color: row.neither_score > 1 ? theme.palette.success.main : theme.palette.error.main
                    }}
                  >
                    {toIndex(row.neither_score)}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      width: '18%',
                      color: row.disagree_score > 1 ? theme.palette.success.main : theme.palette.error.main
                    }}
                  >
                    {toIndex(row.disagree_score)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  )
}

export default LifestyleTableCard;
