import { FillLayer } from "react-map-gl";
const districtZoomThreshold = 8;
const sectorZoomThreshold = 10;

export const areaBaseLayer: FillLayer = {
  id: "area-base",
  type: "fill",
  "source-layer": "postal_area_test",
  maxzoom: 7,
  minzoom: 4,
  paint: {
    "fill-outline-color": "rgba(33,43,58,0.4)",
    "fill-color": [
      "case",
      ["==", ["feature-state", "score"], null],
      "#ECF3F4",
      [
        "interpolate",
        ["linear"],
        ["feature-state", "score"],
        0, '#f7d7ff',
        0.2, '#d5a5e6',
        0.4, '#b175ce',
        0.6, '#8b45b6',
        0.8, '#61089f',
      ],
    ],
    "fill-opacity": [
      "case",
      ["==", ["feature-state", "score"], null],
      0,
      ["case", ["<", ["feature-state", "score"], 0], 0, 1],
    ],
  },
};

export const areaHoverLayer: FillLayer = {
  id: "area-hover",
  type: "fill",
  "source-layer": "postal_area_test",
  maxzoom: 7,
  minzoom: 4,
  paint: {
    // "fill-outline-color": "#FFFFFF",
    "fill-color": "#FFFFFF",
    "fill-opacity": 0.3,
  },
};

export const areaSelectLayer: FillLayer = {
  id: "area-select",
  type: "fill",
  "source-layer": "postal_area_test",
  maxzoom: 7,
  minzoom: 4,
  paint: {
    // "fill-outline-color": "#F5B49F",
    "fill-color": "#F5B49F",
    "fill-opacity": 1.0,
  },
};

export const districtBaseLayer: FillLayer = {
  id: "district-base",
  type: "fill",
  "source-layer": "postal_district_test",
  maxzoom: 9,
  minzoom: 7,
  paint: {
    "fill-outline-color": "rgba(33,43,58,0.4)",
    "fill-color": [
      "interpolate",
      ["linear"],
      ["feature-state", "score"],
      // 0, '#212b3a',
      0, '#f7d7ff',
      0.2, '#d5a5e6',
      0.4, '#b175ce',
      0.6, '#8b45b6',
      0.8, '#61089f',
    ],
    "fill-opacity": [
      "case",
      ["==", ["feature-state", "score"], null],
      0,
      ["case", ["<", ["feature-state", "score"], 0], 0, 1],
    ],
  },
};

export const districtHoverLayer: FillLayer = {
  id: "district-hover",
  type: "fill",
  "source-layer": "postal_district_test",
  maxzoom: 9,
  minzoom: 7,
  paint: {
    // "fill-outline-color": "#FFFFFF",
    "fill-color": "#FFFFFF",
    "fill-opacity": 0.3,
  },
};

export const districtSelectLayer: FillLayer = {
  id: "district-select",
  type: "fill",
  "source-layer": "postal_district_test",
  maxzoom: 9,
  minzoom: 7,
  paint: {
    // "fill-outline-color": "#F5B49F",
    "fill-color": "#F5B49F",
    "fill-opacity": 1.0,
  },
};

export const sectorBaseLayer: FillLayer = {
  id: "sector-base",
  type: "fill",
  "source-layer": "postal_sector_test",
  minzoom: 9,
  paint: {
    "fill-outline-color": "rgba(33,43,58,0.4)",
    "fill-color": [
      "interpolate",
      ["linear"],
      ["feature-state", "score"],
      0, '#f7d7ff',
      0.2, '#d5a5e6',
      0.4, '#b175ce',
      0.6, '#8b45b6',
      0.8, '#61089f',
    ],
    "fill-opacity": [
      "case",
      ["==", ["feature-state", "score"], null],
      0,
      ["case", ["<", ["feature-state", "score"], 0], 0, 1],
    ],
  },
};

export const sectorHoverLayer: FillLayer = {
  id: "sector-hover",
  type: "fill",
  "source-layer": "postal_sector_test",
  minzoom: 9,
  paint: {
    // "fill-outline-color": "#FFFFFF",
    "fill-color": "#FFFFFF",
    "fill-opacity": 0.3,
  },
};

export const sectorSelectLayer: FillLayer = {
  id: "sector-select",
  type: "fill",
  "source-layer": "postal_sector_test",
  minzoom: 9,
  paint: {
    // "fill-outline-color": "#F5B49F",
    "fill-color": "#F5B49F",
    "fill-opacity": 1.0,
  },
};
