import React, { useState, useEffect } from "react";
import {Box, Card, CardActionArea, CardContent, CardHeader, FormGroup, FormControlLabel, Typography, Tooltip, Switch, Grid} from "@mui/material";
import {InsightResult} from "@/types";
import {darken, lighten} from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { sortBy } from "lodash";
import { toPercentage, toIndex } from "@/utils/common"



const scoreTypeMap: {[name: string]: string} = {
  'score': 'Index',
  'rate': 'Penetration'
}

interface ScoreCardProps {
  name: string;
  data: InsightResult[];
}

export const ScoreCard: React.FC<ScoreCardProps> = ({name, data}) => {

  const theme = useTheme();
  const [scores, setScores] = useState<InsightResult[]>([]);
  const [scoreChecked, setScoreChecked] = useState<boolean>(true);
  const [scoreType, setScoreType] = useState<string>('score');

  useEffect(() => {
    setScores(sortBy(data, scoreType).reverse())
  }, [data, scoreType])

  const handleScoreTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setScoreChecked(event.target.checked);
    setScoreType(event.target.checked ? 'score' : 'rate');
  };

  function toScore(score: InsightResult, mode: string) {
    if (mode === 'score') {
      let minScore = Math.min(...scores.map(x => x[scoreType as keyof InsightResult] as number));
      let maxScore = Math.max(...scores.map(x => x[scoreType as keyof InsightResult] as number));
      return (((0.9 - 0.05) * (score.score - minScore)) / (maxScore - minScore)) + 0.05;
    } else {
      let minScore = Math.min(...scores.map(x => x[scoreType as keyof InsightResult] as number));
      let maxScore = Math.max(...scores.map(x => x[scoreType as keyof InsightResult] as number));
      if (minScore >= 1) {
        return 1
      } else {
        return (((0.9 - 0.05) * (score.rate - minScore)) / (maxScore - minScore)) + 0.05;
      }
    }
  }

  function toLabel(score: InsightResult, mode: string) {
    if (mode === 'score') {
      return toIndex(score.score);
    } else {
      return toPercentage(score.rate)
    }
  }

  function toScoreArray(values: number[], step: number) {

    let start = (Math.round((Math.min(...values) * 100) / 10) * 10) / 100;
    let stop = (Math.ceil((Math.max(...values) * 100) / 5) * 5) / 100;

    let numSteps = Math.ceil((stop - start) / step);
    if (numSteps > 6) {
      step = step * 2
    }

    return Array.from(
      { length: (stop - start) / step + 1 },
      (value, score) => start + score * step
    );
  }

  function toRateArray(values: number[], step: number) {

    let start = (Math.round((Math.min(...values) * 100) / 10) * 10) / 100;
    let stop = (Math.ceil((Math.max(...values) * 100) / 5) * 5) / 100;

    let numSteps = Math.ceil((stop - start) / step);
    if (numSteps > 6) {
      step = step * 2
    } else if (numSteps < 4) {
      step = step / 2
    }

    return Array.from(
      { length: (stop - start) / step + 1 },
      (value, score) => start + score * step
    );
  }

  return (
    <Card elevation={8} sx={{ display: 'flex', flexDirection: 'column', height: '600px', overflow: 'hidden'}}>
      <CardHeader
        disableTypography
        title={
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h5">
              {name}
            </Typography>
            <FormGroup>
              <Tooltip
                placement="top"
                title={`
                  Change the type of data shown. 
                  The penetration shows the percentage of the audience that is likely to have this subscription
                  and the score represents how this compares to the national average.
                `}
              >
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={scoreChecked}
                      onChange={handleScoreTypeChange}
                    />}
                  label={scoreTypeMap[scoreType]}
                  labelPlacement="start"
                />
              </Tooltip>
            </FormGroup>
          </Box>
        }
        subheader={
          <Typography color="textSecondary">
            Which media subscriptions are this audience likely to purchase
          </Typography>
        }
      />
      <CardContent sx={{ height: '600px', overflowY: "auto" }}>
        <Grid container spacing={1}>
          <Grid item xs={5}>
          </Grid>
          { scoreType === 'score' &&
            <Grid item xs={7} sx={{ borderBottom: '1px solid white'}} display="flex" justifyContent="space-between">
              {toScoreArray(scores.map(x => x[scoreType as keyof InsightResult] as number), 0.1).map((val, idx) => (
                <Typography key={idx} variant="caption">{toIndex(val)}</Typography>
              ))
              }
            </Grid>
          }
          { scoreType === 'rate' &&
            <Grid item xs={7} sx={{ borderBottom: '1px solid white'}} display="flex" justifyContent="space-between">
              {toRateArray(scores.map(x => x[scoreType as keyof InsightResult] as number), 0.05).map((val, idx) => (
                  <Typography key={idx} variant="caption">{toPercentage(val, 0)}</Typography>
                ))
              }
            </Grid>
          }
          {scores.map((score, idx) => (
            <>
              <Grid item xs={5}>
                <Typography>{score.tag_name}</Typography>
              </Grid>
              <Grid item xs={7}>
                <Tooltip placement="right" title={`${scoreTypeMap[scoreType]}: ${toLabel(score, scoreType)}`}>
                  <Box
                    sx={{
                      width: `${(toScore(score, scoreType)) * 100}%`,
                      height: 24,
                      backgroundColor: darken(theme.palette.primary.main, 0.9 - Math.min(toScore(score, scoreType), 1)),
                      borderRadius: "0px 4px 4px 0px"
                  }}
                  />
                </Tooltip>
              </Grid>
            </>
          ))}
        </Grid>
      </CardContent>
    </Card>
  )

}

export default ScoreCard;
