import { AxiosRequestConfig } from "axios";

import { ApiError } from "@/interfaces/api";
import { FileDownloadResult, FileInfo, Tag } from "@/types";

import { fetchData } from "@/api/utils";

type CustomerLookalikesApiResponse = {
  data: FileDownloadResult;
  error: ApiError | null;
};

export const customerLookalikes = async (
  accessToken: string,
  fileInfo: FileInfo,
  exportOption: string,
  segment?: string
): Promise<CustomerLookalikesApiResponse> => {
  let url = `${process.env.REACT_APP_API_URL}/t7s/customer/lookalikes?csv=true&link=true&export_type=${exportOption}`;
  if (segment) url = url + `&segment=${segment}`;

  url = fileInfo.hasHeader ? url : url + `&header=${fileInfo.header}`;

  const config: AxiosRequestConfig = {
    method: "POST",
    url,
    headers: {
      "content-type": "text/csv",
      Authorization: `Bearer ${accessToken}`,
    },
    data: fileInfo.file,
  };

  return fetchData(config);
};
