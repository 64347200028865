import React, {useEffect, useState} from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid,
  Box,
  FormGroup,
  Tooltip,
  FormControlLabel, Switch
} from "@mui/material";
import {InsightResult} from "@/types";
import {toPercentage, toIndex} from "@/utils/common";
import {darken, lighten} from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { sortBy } from "lodash";


import {Bar} from "react-chartjs-2";
import {Chart as ChartJS, Legend, BarElement, LinearScale, CategoryScale, Title, Tooltip as ChartTooltip, TooltipItem} from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(
  BarElement,
  Legend,
  Title,
  ChartTooltip,
  LinearScale,
  CategoryScale,
  ChartDataLabels
);

const scoreTypeMap: {[name: string]: string} = {
  'score': 'Index',
  'rate': 'Penetration'
}

interface BarGraphCardProps {
  name: string;
  data: InsightResult[];
}

export const BarGraphCard: React.FC<BarGraphCardProps> = ({name, data}) => {

  const theme = useTheme();

  const [scores, setScores] = useState<number[]>([]);
  const [baseline, setBaseline] = useState<number[]>([]);
  const [scoreChecked, setScoreChecked] = useState<boolean>(true);
  const [scoreType, setScoreType] = useState<string>('score');

  const labels = sortBy(data, 'tag_idx').map(x => x.tag_name);
  const minScore = Math.min(...scores);
  const maxScore = Math.max(...scores);
  const chartMin = scoreType === 'score' ? minScore < -0.1 ? undefined : -0.1 : undefined;
  const chartMax = scoreType === 'score' ? maxScore > 0.1 ? undefined : 0.1 : undefined;

  useEffect(() => {
    if (scoreType === 'rate') {
      setScores(sortBy(data, 'tag_idx').map((row) => row[scoreType as keyof InsightResult] as number))
      setBaseline(sortBy(data, 'tag_idx').map((row) => row.rate / row.score))
    } else {
      setScores(sortBy(data, 'tag_idx').map((row) => row[scoreType as keyof InsightResult] as number - 1))
      setBaseline([])
    }
  }, [scoreType, data]);

  const handleScoreTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setScoreChecked(event.target.checked);
    setScoreType(event.target.checked ? 'score' : 'rate');
  };

  const combineWords = (arr: string | string[]) => {
    if (typeof(arr) === 'string') {
      return arr
    } else {
      return arr.reduce((acc: string[], word: string) => {
        if (word.length <= 4) {
          if (acc.length > 0 && acc[acc.length - 1].length <= 4) {
            acc[acc.length - 1] += ' ' + word;
          } else {
            acc.push(word);
          }
        } else {
          acc.push(word);
        }
        return acc
      }, []);
    }
  };

  const parseLabel = (label: string) => {
    label = label.replace(/\(.+\)/g, "")
    if (label.includes(" - ")){
      if (label.length < 15) {
        return label.replace(/\s/g, "")
      } else {
        let parts = label.split(" - ");
        return [parts[0] + ' -', ...parts.slice(1)];
      }
    } else if (label.length > 11 && label.includes("-")) {
      let parts = label.split("-");
      return [parts[0] + ' -', ...parts.slice(1)];
    } else if (label.includes("/")){
      return label.split("/")
    } else if (label.length > 11){
      return label.split(" ")
    } else {
      return label
    }
  }

  return (
    <Card elevation={8} sx={{ height: 254 }}>
      <CardHeader
        disableTypography
        title={
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">
              {name}
            </Typography>
            <FormGroup>
              <Tooltip
                placement="top"
                title={`
                  Change the type of data shown. 
                  The reach shows the percentage of the audience that is in each group
                  and the score represents how this compares to the national average.
                `}
              >
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={scoreChecked}
                      onChange={handleScoreTypeChange}
                    />}
                  label={scoreTypeMap[scoreType]}
                  labelPlacement="start"
                />
              </Tooltip>
            </FormGroup>
          </Box>
        }
      />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box width="100%">
              <Bar
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    y: {
                      min: chartMin,
                      max: chartMax,
                      ticks: {
                        color: theme.palette.text.secondary,
                        callback: function(value) {
                          return scoreType === 'rate'
                            ? toPercentage(value as number, 0)
                            : Math.round((value as number + 1) * 100) / 100
                        }
                      },
                      grid: {
                        color: function (context) {
                          return context.tick.value === 0 ? theme.palette.text.secondary : darken(theme.palette.text.secondary, 0.5)
                        },
                        lineWidth: function (context) {
                          return context.tick.value === 0 ? 1 : 1
                        }
                      },
                      border: {
                        color: darken(theme.palette.text.secondary, 0.5)
                      },
                    },
                    x: {
                      ticks: {
                        color: theme.palette.text.secondary,
                        font: {
                          size: 10
                        }
                      },
                      grid: {
                        display: false,
                        color: darken(theme.palette.text.secondary, 0.5)
                      },
                      border: {
                        color: darken(theme.palette.text.secondary, 0.5)
                      },
                    }
                  },
                  plugins: {
                    legend: {
                      display: false
                    },
                    tooltip: {
                      displayColors: false,
                      backgroundColor: lighten(theme.palette.background.default, 0.4),
                      callbacks: {
                        label: function(context) {
                          return scoreType === 'rate'
                            ? `${context.dataset.label}: ${toPercentage(context.parsed.y)}`
                            : toIndex(context.parsed.y + 1)
                        }
                      }
                    }
                  }
                }}
                data={{
                  labels: labels.map(label => combineWords(parseLabel(label))),
                  datasets: baseline.length > 0 ? [
                    {
                      label: 'Audience',
                      data: scores,
                      backgroundColor: scores.map(x => x > 0 ? theme.palette.primary.dark: theme.palette.primary.main),
                      borderColor: theme.palette.primary.dark,
                      borderRadius: 4,
                      datalabels: {
                        display: false
                      }
                    },
                    {
                      label: 'Baseline',
                      data: baseline,
                      backgroundColor: theme.palette.text.secondary,
                      borderColor: theme.palette.text.secondary,
                      borderRadius: 4,
                      datalabels: {
                        display: false
                      }
                    }
                  ] : [
                    {
                      label: 'Audience',
                      data: scores,
                      backgroundColor: scores.map(x => x > 0 ? theme.palette.primary.dark: theme.palette.primary.main),
                      borderColor: theme.palette.primary.dark,
                      borderRadius: 4,
                      datalabels: {
                        color: theme.palette.text.primary,
                        font: {
                          size: 10
                        },
                        anchor: function(context) {
                          let value = context.dataset.data[context.dataIndex];
                          if (value) {
                            return value > 0 ? 'end' : 'start'
                          } else {
                            return 'end'
                          }
                        },
                        align: function(context) {
                          let value = context.dataset.data[context.dataIndex];
                          if (value) {
                            if (value > context.chart.scales.y.max - 0.05){
                              return 'start'
                            } else if (value < context.chart.scales.y.min + 0.05) {
                              return 'end'
                            } else {
                              return value > 0 ? 'end' : 'start'
                            }
                          } else {
                            return 'end'
                          }
                        },
                        offset: 0,
                        clip: false,
                        // clamp: false,
                        formatter: function(value, context) {
                          return toIndex(value + 1);
                        }
                      }
                    }
                  ]
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )

}

export default BarGraphCard;
