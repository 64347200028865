import React from "react";
import {Card, CardContent, CardHeader, Typography, Grid} from "@mui/material";
import {InsightResult} from "@/types";
import {toPercentage, toIndex} from "@/utils/common";
import {darken, lighten} from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";

import {Bubble} from "react-chartjs-2";
import {Chart as ChartJS, Legend, LinearScale, Title, Tooltip as ChartTooltip, TooltipItem} from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(
  Legend,
  Title,
  ChartTooltip,
  LinearScale,
  ChartDataLabels
);

interface CustomTooltipItem extends TooltipItem<'bubble'> {
  raw: {
    x: number,
    y: number,
    name: string,
  }
}


interface ScoreGraphCardProps {
  name: string;
  data: InsightResult[];
}

export const ScoreGraphCard: React.FC<ScoreGraphCardProps> = ({name, data}) => {

  const theme = useTheme();

  const scores = data.map((row, idx) => (
    {
      name: row.tag_name,
      y: row.score,
      x: row.rate,
      r: Math.max(row.rate * 50, 5)
    }
  ))

  return (
    <Card elevation={8}>
      <CardHeader
        title={name}
        subheader={'Which media subscriptions are this audience likely to purchase'}
      />
      <CardContent sx={{ height: 300 }}>
        <Bubble
          options={{
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              y: {
                title: {
                  display: false,
                  text: 'How this compares to the average',
                  color: darken(theme.palette.text.secondary, 0.5)
                },
                grid: {
                  color: darken(theme.palette.text.secondary, 0.5)
                },
                ticks: {
                  color: theme.palette.text.secondary,
                  callback: function(value) { return toIndex(value as number)}
                },
                border: {
                  color: darken(theme.palette.text.secondary, 0.5)
                },

              },
              x: {
                title: {
                  display: true,
                  text: '% of audience likely to purchase this media subscription',
                  color: darken(theme.palette.text.secondary, 0.5)
                },
                grid: {
                  color: darken(theme.palette.text.secondary, 0.5)
                },
                ticks: {
                  color: theme.palette.text.secondary,
                  callback: function(value) { return toPercentage(value as number, 0)}
                },
                border: {
                  color: darken(theme.palette.text.secondary, 0.5)
                },
              }

            },

            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                displayColors: false,
                backgroundColor: lighten(theme.palette.background.default, 0.4),
                callbacks: {
                  label: function (context: CustomTooltipItem) {
                    return `${context.raw.name as String}`;
                  }
                }
              }
            }
          }}
          data={{
            datasets: [
              {
                label: 'A',
                data: scores,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.dark,
                datalabels: {
                  display: false
                }
              }
            ]
          }}

        />
      </CardContent>
    </Card>
  )

}

export default ScoreGraphCard;
