import React from "react";

export const Logo = () => (
  <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1196.77 499.18">
    <g id="Layer_1-2" data-name="Layer_1">
      <g>
        <path fill="#b557f6" d="M24.1,62.36V6.89H5.53V1.31h43.17v5.58h-18.66v55.47h-5.93Z"/>
        <path fill="#b557f6"
              d="M56.81,62.36V1.31h5.93v27.99h31.48V1.31h5.93v61.05h-5.93v-27.65h-31.48v27.65h-5.93Z"/>
        <path fill="#b557f6"
              d="M114.97,62.36V1.31h34.19v5.32h-28.26v22.67h28.26v5.41h-28.26v22.24h28.26v5.41h-34.19Z"/>
        <path fill="#b557f6" d="M160.76,62.36l29.13-53.9h-30.7V3.05h39.59l-31.48,59.3h-6.54Z"/>
        <path fill="#b557f6"
              d="M222.76,63.66c-6.75,0-12.07-1.8-15.96-5.41-3.9-3.6-5.84-8.55-5.84-14.83h5.93c0,4.59,1.42,8.24,4.27,10.94,2.85,2.7,6.71,4.06,11.6,4.06,4.36,0,7.76-1.02,10.2-3.05,2.44-2.03,3.66-4.88,3.66-8.55,0-5.81-3.52-9.71-10.55-11.69l-10.29-3.14c-8.66-2.38-12.99-7.67-12.99-15.87,0-4.83,1.76-8.72,5.28-11.69,3.52-2.97,8.1-4.45,13.74-4.45,5.93,0,10.65,1.64,14.17,4.93,3.52,3.29,5.28,7.75,5.28,13.39h-5.93c0-4.19-1.21-7.43-3.62-9.72-2.41-2.3-5.77-3.44-10.07-3.44-3.9,0-7.02.97-9.37,2.92-2.35,1.95-3.53,4.52-3.53,7.72,0,2.97.93,5.41,2.79,7.33,1.86,1.92,4.74,3.43,8.63,4.53l9.94,2.97c8.25,2.33,12.38,7.67,12.38,16.05,0,5.18-1.77,9.3-5.32,12.38-3.55,3.08-8.34,4.62-14.39,4.62Z"/>
        <path fill="#b557f6" d="M263.05,62.36V6.89h-18.58V1.31h43.17v5.58h-18.66v55.47h-5.93Z"/>
        <path fill="#b557f6"
              d="M324.01,62.36l-3.58-10.99h-27.03l-3.49,10.99h-6.37L304.3,1.31h5.32l20.76,61.05h-6.37ZM295.15,46.05h23.63l-11.86-36.8-11.77,36.8Z"/>
        <path fill="#b557f6"
              d="M380.96,22.06c0,4.83-1.25,9.01-3.75,12.56-2.5,3.55-5.81,5.99-9.94,7.33l12.91,20.41h-6.8l-12.21-19.45h-15.7v19.45h-5.93V1.31h21.28c5.81,0,10.62,1.95,14.43,5.84,3.81,3.9,5.71,8.87,5.71,14.91ZM345.47,6.63v30.87h15.09c4.19,0,7.64-1.45,10.38-4.36,2.73-2.91,4.1-6.6,4.1-11.08s-1.37-8.17-4.1-11.08c-2.73-2.91-6.19-4.36-10.38-4.36h-15.09Z"/>
        <path fill="#b557f6"
              d="M409.3,63.66c-6.75,0-12.07-1.8-15.96-5.41-3.9-3.6-5.84-8.55-5.84-14.83h5.93c0,4.59,1.42,8.24,4.27,10.94,2.85,2.7,6.71,4.06,11.6,4.06,4.36,0,7.76-1.02,10.2-3.05,2.44-2.03,3.66-4.88,3.66-8.55,0-5.81-3.52-9.71-10.55-11.69l-10.29-3.14c-8.66-2.38-12.99-7.67-12.99-15.87,0-4.83,1.76-8.72,5.28-11.69,3.52-2.97,8.1-4.45,13.74-4.45,5.93,0,10.65,1.64,14.17,4.93,3.52,3.29,5.28,7.75,5.28,13.39h-5.93c0-4.19-1.21-7.43-3.62-9.72-2.41-2.3-5.77-3.44-10.07-3.44-3.9,0-7.02.97-9.37,2.92-2.35,1.95-3.53,4.52-3.53,7.72,0,2.97.93,5.41,2.79,7.33,1.86,1.92,4.74,3.43,8.63,4.53l9.94,2.97c8.25,2.33,12.38,7.67,12.38,16.05,0,5.18-1.77,9.3-5.32,12.38-3.55,3.08-8.34,4.62-14.39,4.62Z"/>
      </g>
      <path fill="#b557f6"
            d="M223.35,172.89v34.49h-18.66c-5.09,23.94-16.25,42.46-33.5,55.56-17.25,13.1-39.44,19.65-66.58,19.65-31.29,0-56.59-9.75-75.91-29.26C9.38,233.82-.18,208.99,0,178.83,0,148.86,9.57,123.79,28.7,103.62c19.13-20.17,45.28-30.25,78.45-30.25,26.2,0,47.93,6.32,65.17,18.94,17.25,12.63,26.72,28.65,28.41,48.06h-50.04c-1.89-8.48-6.65-15.36-14.28-20.64-7.64-5.27-17.3-7.91-28.98-7.91-19.42,0-34.26,6.13-44.53,18.38-10.27,12.25-15.32,28.18-15.12,47.78-.19,18.85,5.04,34.35,15.69,46.51,10.65,12.16,24.74,18.24,42.27,18.24,27.71,0,44.96-11.78,51.74-35.34h-35.34v-34.49h101.21Z"/>
      <path fill="#b557f6"
            d="M394.11,150.56c0,14.33-3.3,27.1-9.9,38.31-6.6,11.22-15.65,19.65-27.14,25.3l34.78,62.76h-52.02l-31.1-54.85h-24.03v54.85h-45.8V79.03h84.25c20.55,0,37.51,6.74,50.89,20.21,13.38,13.48,20.07,30.58,20.07,51.32ZM284.7,116.35v68.42h31.38c9.24,0,16.83-3.2,22.76-9.61,5.94-6.41,8.91-14.6,8.91-24.6s-2.97-18.19-8.91-24.6c-5.93-6.41-13.52-9.61-22.76-9.61h-31.38Z"/>
      <path fill="#b557f6"
            d="M515.15,79.02h-46.07l-67.02,197.92h48.36l6.77-24.04h.02l10.16-35.91,4.87-17.17,15.93-56.28,4.08-14.48,24.89,87.92,10.14,35.91h.04l6.77,24.04h48.36l-67.3-197.92Z"/>
      <path fill="#b557f6"
            d="M613.91,276.93l-66.44-197.91h49.19l40.99,139.66,40.99-139.66h49.19l-66.44,197.91h-47.5Z"/>
      <path fill="#b557f6" d="M744.72,276.93V79.03h45.8v197.91h-45.8Z"/>
      <path fill="#b557f6" d="M857.52,276.93V119.74h-54.28v-40.71h154.36v40.71h-54.56v157.19h-45.52Z"/>
      <path fill="#b557f6"
            d="M1097.77,79.03h50.61l-98.1,197.91h-50.89l36.75-74.64-70.12-123.27h53.15l42.12,78.03,36.47-78.03Z"/>
      <polygon fill="#b557f6" points="487.96 129.07 483.89 143.54 467.96 199.82 487.96 129.07"/>
      <path fill="#b557f6"
            d="M946.14,499.18c-31.46,0-56.72-9.58-75.77-28.73-19.06-19.15-28.59-43.8-28.59-73.97s9.53-54.82,28.59-73.97c19.06-19.15,44.31-28.73,75.77-28.73,28.12,0,51.02,6.9,68.69,20.68,17.67,13.79,27.8,32.24,30.39,55.37h-47.74c-2.59-11.1-8.33-19.84-17.21-26.23s-20.17-9.58-33.86-9.58c-18.14,0-32.29,5.6-42.47,16.79-10.18,11.2-15.26,26.42-15.26,45.66s5.09,34.46,15.26,45.66c10.17,11.2,24.33,16.79,42.47,16.79,13.69,0,24.98-3.19,33.86-9.58s14.61-15.13,17.21-26.23h47.74c-2.59,23.13-12.72,41.59-30.39,55.37-17.67,13.79-40.57,20.68-68.69,20.68Z"/>
      <path fill="#b557f6"
            d="M104.36,499.18c-31.46,0-56.72-9.58-75.77-28.73C9.53,451.3,0,426.65,0,396.48s9.53-54.82,28.59-73.97c19.06-19.15,44.31-28.73,75.77-28.73,28.12,0,51.02,6.9,68.69,20.68,17.67,13.79,27.8,32.24,30.39,55.37h-47.74c-2.59-11.1-8.33-19.84-17.21-26.23s-20.17-9.58-33.86-9.58c-18.14,0-32.29,5.6-42.47,16.79-10.18,11.2-15.26,26.42-15.26,45.66s5.09,34.46,15.26,45.66c10.17,11.2,24.33,16.79,42.47,16.79,13.69,0,24.98-3.19,33.86-9.58s14.61-15.13,17.21-26.23h47.74c-2.59,23.13-12.72,41.59-30.39,55.37-17.67,13.79-40.57,20.68-68.69,20.68Z"/>
      <path fill="#b557f6" d="M1098.52,493.63v-154.32h-53.29v-39.97h151.54v39.97h-53.57v154.32h-44.69Z"/>
      <path fill="#b557f6"
            d="M391.19,469.34c-18.87,19.89-44.04,29.84-75.49,29.84s-56.62-9.94-75.49-29.84c-18.87-19.89-28.31-44.17-28.31-72.86s9.44-52.96,28.31-72.86c18.87-19.89,44.04-29.84,75.49-29.84s56.62,9.95,75.49,29.84c18.87,19.89,28.31,44.18,28.31,72.86s-9.44,52.97-28.31,72.86ZM315.7,458.93c17.76,0,31.68-5.97,41.77-17.9,10.08-11.93,15.13-26.78,15.13-44.55s-5.04-32.61-15.13-44.55c-10.09-11.93-24.01-17.9-41.77-17.9s-31.97,5.97-42.05,17.9c-10.09,11.94-15.04,26.78-14.85,44.55-.19,17.76,4.76,32.61,14.85,44.55,10.08,11.93,24.1,17.9,42.05,17.9Z"/>
      <path fill="#8841b9"
            d="M973.13,369.46c-6.12-7.24-14.57-10.86-25.34-10.86s-19.39,3.62-25.51,10.86c-2.5,2.95-4.46,6.2-5.92,9.74h-147.53v-43.78h73.83v-36.08h-118.79v79.86h-18.65v-79.86h-43.02v125.73l-67.44-125.73h-5.55s-43.02,0-43.02,0v125.73l-67.45-125.73h-46.07v79.86h-85.67c-1.47-3.55-3.45-6.79-5.94-9.74-6.12-7.24-14.57-10.86-25.35-10.86s-19.39,3.62-25.51,10.86c-6.12,7.24-9.12,16.25-9.01,27.03-.11,10.77,2.89,19.78,9.01,27.02,6.12,7.25,14.62,10.87,25.51,10.87s19.23-3.62,25.35-10.87c1.7-2.01,3.16-4.16,4.38-6.45h87.23v76.57h43.02v-125.73l67.45,125.73h46.07s2.5,0,2.5,0v-125.73l67.45,125.73h46.07v-76.57h18.65v76.57h118.79v-36.08h-73.83v-40.49h149.07c1.22,2.29,2.67,4.44,4.37,6.46,6.12,7.24,14.62,10.86,25.51,10.86s19.22-3.62,25.34-10.86c6.12-7.24,9.18-16.25,9.18-27.03s-3.06-19.79-9.18-27.03Z"/>
    </g>
  </svg>
);
