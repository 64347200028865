import { FillLayer } from "react-map-gl";

export const areaBaseLayer: FillLayer = {
  id: "area-base",
  type: "fill",
  "source-layer": "areas_output",
  paint: {
    // "fill-outline-color": "rgba(0,0,0,0.1)",
    "fill-color": [
      "case",
      ["==", ["feature-state", "score"], null],
      "#ECF3F4",
      [
        "interpolate",
        ["linear"],
        ["feature-state", "score"],
        0, '#f7d7ff',
        0.2, '#d5a5e6',
        0.4, '#b175ce',
        0.6, '#8b45b6',
        0.8, '#61089f',
      ],
    ],
    "fill-opacity": [
      "case",
      ["==", ["feature-state", "score"], null],
      0,
      ["case", ["<", ["feature-state", "score"], 0], 0, 1],
    ],
  },
};
