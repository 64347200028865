import React from "react";

import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";

import {Order} from "@/types";
import {LifestyleTable, LifestyleTableHeader} from "@/types";


interface LifestyleHeaderProps {
  header: LifestyleTableHeader[];
  column: string;
  order: Order;
  onSort: (event: React.MouseEvent<unknown>, property: keyof LifestyleTable) => void;
}

const LifestyleHeader: React.FC<LifestyleHeaderProps> = ({ header, column, order, onSort }) => {

  const createSortHandler = (property: keyof LifestyleTable) => (event: React.MouseEvent<unknown>) => {
    onSort(event, property);
  }

  return (
    <TableHead>
      <TableRow>
        {header.map((cell) => (
          <TableCell
            key={cell.id}
            align={cell.numeric ? 'right' : 'left'}
            padding={cell.disablePadding ? 'none' : 'normal'}
            sortDirection={column === cell.id ? order : false}
            sx={{
              width: cell.width,
              fontFamily: 'cera-pro-bold'
            }}
          >
            <TableSortLabel
              active={column === cell.id}
              direction={column === cell.id ? order : 'asc'}
              onClick={createSortHandler(cell.id)}
            >
              {cell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default LifestyleHeader;
