import React from "react";

import {Card, CardContent, CardHeader } from "@mui/material";

import {InsightResult} from "@/types";
import {toIndex} from "@/utils/common";

import {Bar} from "react-chartjs-2";
import {Chart as ChartJS, Legend, BarElement, LinearScale, CategoryScale, Title, Tooltip as ChartTooltip} from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {lighten, useTheme} from "@mui/material/styles";
ChartJS.register(
  BarElement,
  Legend,
  Title,
  ChartTooltip,
  LinearScale,
  CategoryScale,
  ChartDataLabels
);

interface LifestyleCardProps {
  name: string;
  data: InsightResult[];
}

export const LifestyleCard: React.FC<LifestyleCardProps> = ({name, data}) => {

  const theme = useTheme();

  const questions = Array.from(new Set(data.map((v) => v.tag_category)));
  console.log(questions);
  const agree = questions.map((question) => {
    const subData = data.filter((x) => x.tag_category === question);
    const d = Math.max(subData.filter(x => x.tag_name === 'Agree')[0].score - 1, 0);
    const n = Math.max((subData.filter(x => x.tag_name === 'Neither Agree nor Disagree')[0].score - 1) / 2, 0);
    return Math.max(d - n, 0)
  })
  const disagree = questions.map((question) => {
    const subData = data.filter((x) => x.tag_category === question);
    const d = Math.max(subData.filter(x => x.tag_name === 'Disagree')[0].score - 1, 0);
    const n = Math.max((subData.filter(x => x.tag_name === 'Neither Agree nor Disagree')[0].score - 1) / 2, 0);
    return Math.max(Math.min((d - n) * -1, 0), -0.15)
  })

  return (
    <Card elevation={8}>
      <CardHeader title={name}/>
      <CardContent sx={{ height: 50 + (questions.length * 30)}}>
        <Bar
          options={{
            responsive: true,
            maintainAspectRatio: false,
            indexAxis: 'y',
            scales: {
              y: {
                stacked: true,
                ticks: {
                  color: theme.palette.text.secondary,
                  callback: function(value) {
                    if (typeof(value) === "number") {
                      if (questions[value].length > 80) {
                        return questions[value].slice(0, 80) + '...'
                      } else {
                        return questions[value]
                      }
                    } else {
                      return value
                    }
                  }
                }
              },
              x: {
                min: -0.2,
                max: 0.2,
                grid: {
                  display: false,
                },
                ticks: {
                  color: theme.palette.text.secondary,
                  callback: function(value) {
                    if (value === 0.1) {
                      return 'Agree'
                    } else if (value === -0.1) {
                      return 'Disagree'
                    } else {
                      return undefined
                    }
                  }
                }
              }
            },
            plugins: {
              legend: {
                display: false
              },
              tooltip: {
                displayColors: false,
                backgroundColor: lighten(theme.palette.background.default, 0.4),
                callbacks: {
                  label: function (tooltipItem) {
                    if (tooltipItem.parsed.x < 0) {
                      return `Disagree: ${toIndex((tooltipItem.parsed.x * -1) + 1)}`;
                    } else {
                      return `Agree: ${toIndex(tooltipItem.parsed.x + 1)}`;
                    }

                  }
                }
              }
            }
          }}
          data={{
            labels: questions,
            datasets: [
              {
                label: 'Agree',
                data: agree,
                backgroundColor: theme.palette.primary.dark,
                barThickness: 20,
                datalabels: {
                  display: function(context) {
                    let value = context.dataset.data[context.dataIndex];
                    if (value) {
                      return value > 0
                    } else {
                      return false
                    }
                  },
                  color: theme.palette.text.primary,
                  font: {
                    size: 10
                  },
                  anchor: 'end',
                  align: 'end',
                  formatter: function(value, context) {
                    return toIndex(value + 1);
                  }
                }
              },
              {
                label: 'Disagree',
                data: disagree,
                backgroundColor: theme.palette.primary.main,
                barThickness: 20,
                datalabels: {
                  display: function(context) {
                    let value = context.dataset.data[context.dataIndex];
                    if (value) {
                      return value < 0
                    } else {
                      return false
                    }
                  },
                  color: theme.palette.text.primary,
                  font: {
                    size: 10
                  },
                  anchor: 'start',
                  align: 'start',
                  formatter: function(value, context) {
                    return toIndex((value * -1) + 1);
                  }
                }
              }
            ]
          }}
        />
      </CardContent>
    </Card>
  )
}

export default LifestyleCard;
