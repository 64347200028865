import React from "react";
import {Card, CardContent, CardHeader} from "@mui/material";
import {InsightResult} from "@/types";
import {toPercentage} from "@/utils/common";
import {darken, lighten} from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";

import {Bubble} from "react-chartjs-2";
import {Chart as ChartJS, Legend, LinearScale, Title, Tooltip as ChartTooltip, TooltipItem} from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(
  Legend,
  Title,
  ChartTooltip,
  LinearScale,
  ChartDataLabels
);

interface CustomTooltipItem extends TooltipItem<'bubble'> {
  raw: {
    x: number,
    y: number,
    name: string,
  }
}



const mediaQuintiles: {[name: string]: number} = {
  'Quintile 1 (Highest)': 3,
  'Quintile 2': 2,
  'Quintile 3': 1,
  'Quintile 4': -1,
  'Quintile 5 (Lowest)': -2,
  'None': -3,
}

interface MediaGraphCardProps {
  name: string;
  data: InsightResult[];
  groups: string[];
}

export const MediaGraphCard: React.FC<MediaGraphCardProps> = ({name, data, groups}) => {

  const theme = useTheme();

  const scores = groups.map((group, idx) => (
    {
      name: group,
      y: (data.filter((x) => x.tag_category === group).slice(0, 3).map(x => mediaQuintiles[x.tag_name] * x.score).reduce((a, b) => a + b, 0) / 3) + 3,
      x: data.filter((x) => x.tag_category === group).filter((x) => ['Quintile 1 (Highest)', 'Quintile 2'].includes(x.tag_name)).map(x => x.rate).reduce((a, b) => a + b, 0),
      r: data.filter((x) => x.tag_category === group).filter((x) => ['Quintile 1 (Highest)', 'Quintile 2'].includes(x.tag_name)).map(x => x.rate).reduce((a, b) => a + b, 0) * 30,
    }
  ))

  return (
    <Card elevation={8}>
      <CardHeader
        title={name}
        subheader={'Which media type is more likely to resonate with this audience'}
      />
      <CardContent sx={{ height: 300 }}>
        <Bubble
          options={{
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              y: {
                title: {
                  display: false,
                  text: 'How this compares to the average',
                  color: darken(theme.palette.text.secondary, 0.5)
                },
                grid: {
                  color: darken(theme.palette.text.secondary, 0.5)
                },
                ticks: {
                  color: theme.palette.text.secondary,
                  callback: function(value) {
                    if (value === 0.5) {
                      return 'None'
                    } else if (value === 1.5){
                      return 'Low'
                    } else if (value === 3.5){
                      return 'Med'
                    } else if (value === 5.5){
                      return 'High'
                    } else {
                      return undefined
                    }
                  }
                },
                border: {
                  color: darken(theme.palette.text.secondary, 0.5)
                },
              },
              x: {
                title: {
                  display: true,
                  text: '% of audience likely to use this media type',
                  color: darken(theme.palette.text.secondary, 0.5)
                },
                grid: {
                  color: darken(theme.palette.text.secondary, 0.5)
                },
                ticks: {
                  color: theme.palette.text.secondary,
                  callback: function(value) { return toPercentage(value as number, 0)}
                },
                border: {
                  color: darken(theme.palette.text.secondary, 0.5)
                },
              }

            },

            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                displayColors: false,
                backgroundColor: lighten(theme.palette.background.default, 0.4),
                callbacks: {
                  label: function (context: CustomTooltipItem) {
                    return `${context.raw.name as String}`;
                  }
                }
              }
            }
          }}
          data={{
            datasets: [
              {
                label: 'A',
                data: scores,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.dark,
                datalabels: {
                  display: false
                }
              }
            ]
          }}

        />
      </CardContent>
    </Card>
  )

}

export default MediaGraphCard;
