import React, { useState, useEffect } from "react";
import {Box, Card, CardActionArea, CardContent, CardHeader, Chip, IconButton, Typography, Tooltip, Collapse} from "@mui/material";
import {ExpandMoreRounded, ExpandLessRounded} from "@mui/icons-material";
import {InsightGraph} from "@/components/atoms";
import {CsvBuilder} from "filefy";


type GraphViewData = {
  id: number;
  score: number;
  tag_name: string;
  tag_group: string;
}

interface GraphViewCardProps {
  name: string;
  score: number | null;
  data: GraphViewData[];
  scoreView?: string;
  startExpanded: boolean;
}

export const GraphViewCard: React.FC<GraphViewCardProps> = ({name, score, data, scoreView, startExpanded}) => {

  const [expand, setExpand] = useState<boolean>(startExpanded);

  const onExpand = () => {
    setExpand(!expand);
  }

  const downloadTable = (
    data: { score: number; tag_name: string; tag_group: string; id: number }[]
  ) => {
    const tag_group = data[0].tag_group;
    const rows = data.map((x) => [x.tag_name, x.score]);
    const builder = new CsvBuilder(`${tag_group}.csv`);
    builder
      .setDelimeter(",")
      .setColumns(["Name", "Index"])
      .addRows(rows.map((row) => row.map((value) => String(value)))) // Convert numbers to strings
      .exportFile();
  };

  const scoreColor = score && scoreView ?
    scoreView === 'index' ?
      score >= 1 ? "primary" : "error"
      : score >= 0 ? "primary" : "error"
  : "primary"

  return (
    <Card elevation={8}>
      <CardActionArea onClick={onExpand}>
        <CardHeader
          disableTypography
          title={
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography variant="h6" sx={{ mr: 2 }}>{name}</Typography>
              {score &&
                <Chip
                  label={score}
                  color={scoreColor}
                  size="small"
                />
              }
            </Box>
          }
          action={
            <Tooltip title={"Expand Group"}>
              <IconButton
                color="inherit"
                onClick={onExpand}
                size="small"
                edge="end"
              >
                {expand ? <ExpandLessRounded /> : <ExpandMoreRounded />}
              </IconButton>
            </Tooltip>
          }
        />
      </CardActionArea>
      <Collapse in={expand}>
        <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
          <InsightGraph data={data} scoreView={scoreView} />
        </CardContent>
      </Collapse>
    </Card>
  )

}

export default GraphViewCard;
