import React from "react";
import { CsvBuilder } from "filefy";
import { Grid } from "@mui/material";

import { InsightResult } from "@/types";

import {getAverage} from "@/utils/common";
import {GraphViewCard} from "./GraphViewCard";


interface GraphViewProps {
  tab: string;
  data: InsightResult[];
  categories: string[];
  scoreView?: string;
}

const GraphView: React.FC<GraphViewProps> = ({ tab, categories, data, scoreView }) => {

  const downloadTable = (
    data: { score: number; tag_name: string; tag_category: string, tag_group: string; id: number }[]
  ) => {
    const group = data[0].tag_category;
    const rows = data.map((x) => [x.tag_name, x.score]);
    const builder = new CsvBuilder(`${group}.csv`);
    builder
      .setDelimeter(",")
      .setColumns(["Name", "Index"])
      .addRows(rows.map((row) => row.map((value) => String(value)))) // Convert numbers to strings
      .exportFile();
  };

  return (
    <>
      {categories.map((group, idx) => {
        const subData = data.filter((x) => x.tag_category === group);
        const tabData = subData.map((x) => ({
          id: x.tag_id,
          score:
            scoreView && scoreView === "index"
              ? Math.round(x.score * 100) / 100
              : Math.round(x.zscore ? x.zscore : x.score) / 100,
          tag_name: x.tag_name,
          tag_category: x.tag_category,
          tag_group: x.tag_group,
        }));

        const groupScore = tab === 'Interests' ?
          Math.round((subData.filter((x) => x.tag_name === group)[0][scoreView && scoreView === "index" ? 'score' : 'zscore'] || 0) * 100) / 100
          : ['Demographics'].includes(tab) ? null
            : Math.round(getAverage(subData.slice(0, 10).map((x) => scoreView && scoreView === "index" ? x.score : x.zscore ? x.zscore : x.score)) * 100) / 100;


        return (
          <Grid item xs={6} key={`insight-${idx}`}>
            <GraphViewCard
              name={group}
              score={groupScore}
              data={tabData}
              scoreView={scoreView}
              startExpanded={idx < 2}
            />
          </Grid>
        );
      })}
    </>
  );
};

export default GraphView;
