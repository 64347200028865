import { tabs } from "@/constants";
import { useThemeMode } from "@/context/theme-context";
import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

import { ErrorPanel, FetchData } from "@/components/atoms";
import {
  PenPortrait,
  SimpleMap,
} from "@/components/molecules";

import {AudienceDiscoverInsight, AudienceSize, VariableGroup, Tag, InsightResult} from "@/types";
import { ScoreTab, MediaTab, DemographicsTab, LifestyleTab } from '@/components/molecules'

import useStyles from "./AudienceDiscover.styles";
import useAudienceDiscover from "./use-audience-discover";

interface AudienceDiscoverProps {
  tags: Array<Tag>;
  queryGroups: Array<VariableGroup>;
  audienceSize: AudienceSize | null;
  geo: string;
  insightResult: AudienceDiscoverInsight | null;
  onInsightUpdate: (insightResult: AudienceDiscoverInsight) => void;
}

const AudienceDiscover: React.FC<AudienceDiscoverProps> = ({
  tags,
  audienceSize,
  geo,
  insightResult,
  onInsightUpdate,
}) => {
  const { darkMode } = useThemeMode();

  const { getAccessTokenSilently } = useAuth0();
  const styles = useStyles();
  const { getAudienceDiscoverData, loading, error } = useAudienceDiscover();

  const mapStyle = darkMode ? "dark" : "light";

  const [data, setData] = useState<AudienceDiscoverInsight | null>(null);
  const [tab, setTab] = useState(0);
  const [tabData, setTabData] = useState<InsightResult[]>([]);
  const [permissions, setPermissions] = useState<string[]>([]);

  const showSnoop = permissions.includes("postcode:snoop");
  const showMastercard = permissions.includes("postcode:mastercard");

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const token = await getAccessTokenSilently();
        const decodedToken: any = JSON.parse(atob(token.split(".")[1]));
        setPermissions(
          decodedToken[
            `https://${process.env.REACT_APP_AUTH0_DOMAIN}/permissions`
          ] || []
        );
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    };

    fetchPermissions();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (insightResult) {
      setData(insightResult);
      setTabData(insightResult.insights.filter((v) => v.tag_type === tabs[tab]));
    }
  }, [tab, insightResult]);

  const handleDiscover = async () => {
    if (audienceSize) {
      const data = await getAudienceDiscoverData(
        audienceSize.request_id || "",
        geo
      );
      if (data) {
        onInsightUpdate(data);
      }
    }
  };

  const handleTabChange = (e: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
    data && setTabData(data.insights.filter((v) => v.tag_type === tabs[newValue]));
  };

  if (data) {

    const renderView = () => {
      if (tabs[tab] === "Audience Overview") {
        return (
          <Grid container item xs={12} spacing={3} direction="row">
            <PenPortrait data={data.pen_portrait} mapStyle={mapStyle} />
          </Grid>
        );
      }

      else if (tabs[tab] === 'Map') {
        return (
          <Grid item xs={12}>
            <div className="map-area">
              <SimpleMap
                locations={data.locations}
                mapStyle={mapStyle}
              />
            </div>
          </Grid>
        );
      }

      else if (tabs[tab] === "Demographics") {
        return (
          <DemographicsTab data={tabData}/>
        );
      }

      else if (tabs[tab] === "Media") {
        return (
          <MediaTab data={tabData}/>
        );
      }

      else if (tabs[tab] === "Lifestyle Statements") {
        return (
          <LifestyleTab data={tabData}/>
        );
      }



      else {
        return (
          <ScoreTab tab={tabs[tab]} data={tabData} tags={tags}/>
        )
      }

    };

    const renderTabs = (tabs: string[]) => {
      return tabs.map((category, idx) => (
        <Tab
          label={category}
          key={idx}
          disabled={
            (category === 'Spend Categories' && !showMastercard)
            || (category === 'Merchant Spending' && !showSnoop)
            || (category === 'Open Banking' && !showSnoop)
          }
        />
      ));
    };

    return (
      <Grid container spacing={3} alignItems="center" alignContent="center">
        <Grid item xs={12}>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            scrollButtons="auto"
            variant="scrollable"
          >
            {renderTabs(tabs)}
          </Tabs>
        </Grid>
        <Grid container item xs={12} spacing={3}>
          {renderView()}
        </Grid>
      </Grid>
    );
  }

  if (loading) return <FetchData message="Calculating insight" />;

  if (error) return <ErrorPanel error={error} errorMessage={error} />;

  return (
    <Grid container spacing={3} alignItems="center" alignContent="center">
      <Grid item xs={12}>
        <Typography gutterBottom variant="h6" className={styles.cardHeader}>
          Investigate
        </Typography>
        <Typography variant="body1" gutterBottom>
          Understand the make-up and profile of your campaign audience.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          onClick={handleDiscover}
        >
          Investigate
        </Button>
      </Grid>
    </Grid>
  );
};

export default AudienceDiscover;
