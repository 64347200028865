import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Box,

} from "@mui/material";
import {DenseTable} from "@/components/atoms";
import {InsightResult, InsightTable, InsightTableHeader} from "@/types";

interface DataTableCardProps {
  name: string;
  data: InsightResult[];
}

export const DataTableCard: React.FC<DataTableCardProps> = ({name, data}) => {

  const header : InsightTableHeader[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
      width: '36%'
    },
    {
      id: 'rate',
      numeric: true,
      disablePadding: false,
      label: '% Aud',
      width: '24%'
    },
    {
      id: 'base',
      numeric: true,
      disablePadding: false,
      label: '% UK',
      width: '22%'
    },
    {
      id: 'index',
      numeric: true,
      disablePadding: false,
      label: 'Index',
      width: '18%'
    }
  ]

  const dataRows: InsightTable[] = data.map((row) => ({
    id: row.tag_id,
    name: row.tag_name,
    rate: row.rate,
    base: row.rate / row.score,
    index: row.score
  }))


  return (
    <Card elevation={8} sx={{display: 'flex', flexDirection: 'column', height: 254, overflow: 'hidden'}}>
      <CardHeader
        disableTypography
        title={
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">
              {name}
            </Typography>
          </Box>
        }
        // sx={{backgroundColor: lighten(theme.palette.background.paper, 0.12)}}
      />
      {/*<CardContent sx={{ pt: 0, height: 254, overflowY: "auto" }}>*/}
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: 254,
          overflow: 'hidden',
          pr: 0,
          pl: 0,
          pt: 0,
          pb: 1,
          "&:last-child": { pb: 1 }
        }}>
        <DenseTable header={header} data={dataRows}/>
      </CardContent>
    </Card>
  )

}

export default DataTableCard;
