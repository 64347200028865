import { AxiosRequestConfig } from "axios";

import { ApiError } from "@/interfaces/api";

import { Tag } from "@/types";

import { fetchData } from "@/api/utils";

type TagsListResponse = {
  data: Tag[];
  error: ApiError | null;
};

const getTagsList = async (accessToken: string): Promise<TagsListResponse> => {
  let url = `${process.env.REACT_APP_API_URL}/t7s/tags`;

  const config: AxiosRequestConfig = {
    method: "GET",
    url,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return fetchData(config);
};

export default getTagsList;
