import { Chip, Theme } from "@mui/material";
import { createStyles, makeStyles, withStyles } from "@mui/styles";
import {darken, lighten} from "@mui/material/styles";

export const useExportStyles = makeStyles((theme: Theme) =>
  createStyles({
    listOption: {
      fontSize: "14px",
    },
    groupHeader: {
      textTransform: "uppercase",
      fontFamily: "cera-pro-bold",
      color: theme.palette.primary.main,
      fontSize: "16px",
    },
    chip: {
      borderRadius: "6px",
      margin: "8px",
    },
    textBox: {
      borderRadius: "4px",
    },
    rowDiv: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    selectionPanel: {
      background: theme.palette.mode == 'dark' ? lighten(theme.palette.background.paper, 0.14) : darken(theme.palette.background.paper, 0.06),
      minHeight: "60px",
    },
    selectionText: {
      paddingLeft: "12px",
      paddingTop: "10px",
      color: "#fff",
      fontSize: "0.75rem",
      lineHeight: "1",
    },
    selectionCount: {
      textAlign: "right",
      paddingRight: "12px",
      paddingTop: "10px",
      color: "#fff",
      fontSize: "0.75rem",
      lineHeight: "1",
    },
    selectionCountError: {
      textAlign: "right",
      paddingRight: "12px",
      paddingTop: "10px",
      color: theme.palette.error.main,
      fontSize: "0.75rem",
      lineHeight: "1",
    },
    buttonGrid: {
      display: "flex",
      justifyContent: "flex-end",
    },
    cardHeader: {
      fontFamily: "cera-pro-bold",
      textTransform: "uppercase",
    },
  })
);

export const BadChip = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}))(Chip);
