import { useThemeMode } from "@/context/theme-context";
import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { BarChart, TableChart } from "@mui/icons-material";
import MenuItem from "@mui/material/MenuItem";
import {
  Box,
  Stack,
  Button,
  Grid,
  Tab,
  Tabs,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";

import { ErrorPanel, FetchData } from "@/components/atoms";
import {
  DemographicsTab,
  GraphView, LifestyleTab, MediaTab,
  PenPortrait, ScoreTab,
  SimpleMap,
  TableView,
} from "@/components/molecules";

import {tabCategories, tabs} from "@/constants";

import {CustomerDiscoverInsight, FileInfo, InsightResult, Tag} from "@/types";

import useStyles from "./CustomerDiscover.styles";
import useCustomerDiscover from "./use-customer-discover";
import {useTagsList} from "@/hooks";

interface CustomerDiscoverProps {
  globalTags: Tag[];
  fileInfo: FileInfo | null;
  insightResult: CustomerDiscoverInsight | null;
  onInsightUpdate: (insight: CustomerDiscoverInsight) => void;
  onTagsUpdate: (tags: Tag[]) => void;
}

const CustomerDiscover: React.FC<CustomerDiscoverProps> = ({
  globalTags,
  fileInfo,
  insightResult,
  onInsightUpdate,
  onTagsUpdate
}) => {
  const { darkMode } = useThemeMode();

  const { getAccessTokenSilently } = useAuth0();
  const styles = useStyles();
  const { getCustomerDiscoverData, loading, error } = useCustomerDiscover();

  const {
    getTagsData,
    loading: tagsListLoading,
    error: tagsListError,
  } = useTagsList();

  const mapStyle = darkMode ? "dark" : "light";

  const [data, setData] = useState<CustomerDiscoverInsight | null>(null);
  const [tags, setTags] = useState<Tag[]>(globalTags);
  const [tab, setTab] = useState<number>(0);
  const [tabData, setTabData] = useState<InsightResult[]>([]);
  const [category, setCategory] = useState<string>('');
  const [segments, setSegments] = useState<string[]>([]);
  const [segment, setSegment] = useState<string>("");
  const [view, setView] = useState<string>("graph");
  const [scoreView, setScoreView] = useState<string>("index");
  const [permissions, setPermissions] = useState<string[]>([]);

  const showSnoop = permissions.includes("postcode:snoop");
  const showMastercard = permissions.includes("postcode:mastercard");

  useEffect(() => {
    if (globalTags.length > 0) {
      setTags(globalTags);
    } else {
      const fetchData = async () => {
        const data = await getTagsData();
        if (data) {
          onTagsUpdate(data);
        }
      };
      fetchData();
    }
  }, [globalTags]);

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const token = await getAccessTokenSilently();
        const decodedToken: any = JSON.parse(atob(token.split(".")[1]));
        setPermissions(
          decodedToken[
            `https://${process.env.REACT_APP_AUTH0_DOMAIN}/permissions`
          ] || []
        );
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    };

    fetchPermissions();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (insightResult) {
      setData(insightResult);
      setTabData(insightResult.insights[0].filter((v) => v.tag_type === tabs[tab]));
      setSegments(insightResult.segments);
      setSegment(insightResult.segments[0]);
    }
  }, [insightResult]);

  useEffect(() => {
    console.log(tab, segment);
    data && console.log(data.insights[segments.indexOf(segment)].filter((v) => v.tag_type === tabs[tab])[0])
    data && setTabData(data.insights[segments.length > 0 ? segments.indexOf(segment) : 0].filter((v) => v.tag_type === tabs[tab]));
  }, [tab, segment]);

  const handleDiscover = async () => {
    const discoverData = await getCustomerDiscoverData(fileInfo);
    if (discoverData) onInsightUpdate(discoverData);
  };

  const handleTabChange = (e: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  }

  const handleSegmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSegment(e.target.value);
  };

  if (data) {

      const renderView = () => {
        if (tabs[tab] === "Audience Overview") {
          return (
            <Grid container item xs={12} spacing={3} direction="row">
              <PenPortrait
                data={data.pen_portrait[segments.length > 0 ? segments.indexOf(segment) : 0]}
                mapStyle={mapStyle}
              />
            </Grid>
          );
        }

        else if (tabs[tab] === 'Map') {
          return (
            <Grid item xs={12}>
              <div className="map-area">
                <SimpleMap
                  locations={data.locations[segments.length > 0 ? segments.indexOf(segment) : 0]}
                  mapStyle={mapStyle}
                />
              </div>
            </Grid>
          );
        }

        else if (tabs[tab] === "Demographics") {
          return (
            <DemographicsTab data={tabData}/>
          );
        }

        else if (tabs[tab] === "Media") {
          return (
            <MediaTab data={tabData}/>
          );
        }

        else if (tabs[tab] === "Lifestyle Statements") {
          return (
            <LifestyleTab data={tabData}/>
          );
        }

        else {
          return (
            <ScoreTab tab={tabs[tab]} data={tabData} tags={tags}/>
          )
        }
      };

      const renderTabs = (tabs: string[]) => {
        return tabs.map((category, idx) => {
          return (
            <Tab
              label={category}
              key={idx}
              disabled={
                (category === 'Spend Categories' && !showMastercard)
                || (category === 'Merchant Spending' && !showSnoop)
                || (category === 'Open Banking' && !showSnoop)
              }
            />
          );
        });
      };

      return (
        <Grid container spacing={3} alignItems="center" alignContent="center">
          <Grid item xs={12}>
            <Tabs
              value={tab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              scrollButtons="auto"
              variant="scrollable"
            >
              {renderTabs(tabs)}
            </Tabs>
          </Grid>
          {segments.length > 0 &&
            <Grid item xs={12}>
              <TextField
                id="segment-select"
                label="Select a segment"
                variant="outlined"
                select
                value={segment}
                onChange={handleSegmentChange}
                style={{ minWidth: "200px" }}
              >
                {segments.sort().map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          }
          {renderView()}
        </Grid>
      )
  }

  if (tagsListLoading) return <FetchData message="Loading tags" />;

  if (loading) return <FetchData message="Calculating insight" />;

  if (error) return <ErrorPanel error={error} errorMessage={error}/>;

  return (
    <Grid container spacing={3} alignItems="center" alignContent="center">
      <Grid item xs={12}>
        <Typography gutterBottom variant="h6" className={styles.cardHeader}>
          Investigate
        </Typography>
        <Typography variant="body1" gutterBottom>
          Match your data to ours to understand the make-up and profile of your
          customers or prospect audience.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          onClick={handleDiscover}
        >
          Investigate
        </Button>
      </Grid>
    </Grid>
  );
};

export default CustomerDiscover;
